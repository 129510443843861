<template>
  <validation-observer ref="simpleRules">
    <b-card-code title="Form Log Kendaraan">
      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Instansi"
            rules="required"
          >
            <b-form-group
              label="Nomor Kendaraan"
              label-for="Nomor Kendaraan"
            >
              <b-form-input
                id="nomorKendaraan"
                v-model="police_number"
                placeholder="Input Nomor Kendaraan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tipe Kendaraan"
            rules="required"
          >
            <b-form-group
              label="Tipe Kendaraan"
              label-for="Tipe Kendaraan"
            >
              <b-form-select
                id="Tipe Kendaraan"
                v-model="vehicle_type"
                placeholder="Input Tipe Kendaraan"
                :options="optionKendaraan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Brand Kendaraan"
            rules="required"
          >
            <b-form-group
              label="Brand Kendaraan"
              label-for="Brand Kendaraan"
            >
              <b-form-input
                id="Brand Kendaraan"
                v-model="brand"
                placeholder="Input Brand Kendaraan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Warna Kendaraan"
            rules="required"
          >
            <b-form-group
              label="Warna Kendaraan"
              label-for="Warna Kendaraan"
            >
              <b-form-input
                id="Warna Kendaraan"
                v-model="color"
                placeholder="Input Warna Kendaraan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal"
            rules="required"
          >
            <b-form-group
              label="Tanggal"
              label-for="Tanggal"
            >
              <b-form-datepicker
                id="date-datepicker"
                v-model="date"
                locale="id"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Kategori"
            rules="required"
          >
            <b-form-group
              label="Kategori"
              label-for="Kategori"
            >
              <v-select
                v-model="kategori"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                placeholder="Pilih Kategori"
                :options="optionsKategori"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="File Surat"
              label-for="FileSurat"
            >
              <!-- Styled -->
              <b-form-file
                id="FileSurat"
                ref="file"
                v-model="file"
                type="file"
                placeholder="Input File Surat"
                drop-placeholder="Drop file here..."
                @change="fileChange"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            class="mt-1"
            name="Tag"
            rules="required"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <label
            for="textarea-default"
            class="mt-1"
          >Deskripsi</label>
          <b-form-textarea
            id="textarea-default"
            v-model="deskripsi"
            placeholder="Textarea"
            rows="3"
          />

          <label
            for="textarea-default"
            class="mt-1"
          >Catatan</label>
          <b-form-textarea
            id="textarea-default"
            v-model="catatan"
            placeholder="Textarea"
            rows="3"
          />

        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Jenis Log"
            rules="required"
          >
            <b-form-group
              label="Jenis Log"
              label-for="Jenis Log"
            >

              <v-select
                v-model="type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                placeholder="Pilih Jenis Log"
                :options="optionLog"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <b-row
            v-for="(row, index) in Pengunjung"
            :key="index"
            class="mt-1"
            style="margin-bottom: 10px;position:relative"
          >
            <h5 class="titlerow">
              Driver {{ index + 1 }}
            </h5>
            <span
              v-show="index !== 0"
              class="warning2"
              @click="deletePengunjung(index)"
            > Hapus Driver </span>
            <b-col md="6">
              <b-form-group
                label="Nama Driver"
                label-for="Nama Driver"
                class="mb-1"
              >
                <b-form-input
                  id="Nama Driver"
                  v-model="row.name"
                  placeholder="Input Nama Driver"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nomor Pengunjung"
                label-for="Nomor Pengunjung"
                class="mb-1"
              >
                <b-form-input
                  id="Nomor Pengunjung"
                  v-model="row.visitor_number"
                  placeholder="Input Nomor Pengunjung"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nomor Telfon"
                label-for="Nomor Telfon"
              >
                <b-form-input
                  id="Kendaraan"
                  v-model="row.phone"
                  placeholder="Input Nomor Telfon"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jenis Kelamin"
                label-for="Jenis Kelamin"
              >
                <b-form-select
                  v-model="row.gender"
                  placeholder="Pilih Tipe Kendaraan"
                  :options="optionGender"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="outline"
            class="bg-gradient mb-2 bt-add"
            type="submit"
            size="sm"
            @click.prevent="addPengunjung"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tambah Driver</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button
        variant="outline-primary"
        class="bg-gradient-primary "
        type="submit"
        @click.prevent="validationForm"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Simpan</span>
      </b-button>

    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from '@axios'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    ToastificationContent,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
  },
  data() {
    return {
      NoSurat: '',
      vehicle_type: '',
      police_number: '',
      brand: '',
      email: '',
      number: '',
      address: '',
      color: '',
      file: [],
      tags: [],
      date: null,
      kategori: [],
      type: [],
      selected: [],
      deskripsi: '',
      catatan: '',
      options: [{ item: '', name: '' }],
      Pengunjung: [
        {
          name: '',
          visitor_number: '',
          phone: '',
          gender: '',
          email: '',
        },
      ],
      optionLog: [
        { value: 'IN', text: 'Masuk' },
        { value: 'OUT', text: 'Keluar' },
      ],
      optionGender: [
        { value: '', text: 'Pilih Jenis Kelamin' },
        { value: 'M', text: 'Pria' },
        { value: 'F', text: 'Wanita' },
      ],
      optionKendaraan: [
        { value: '', text: 'Pilih Tipe Kendaraan' },
        { value: 'Roda 2', text: 'Roda 2' },
        { value: 'Roda 4', text: 'Roda 4' },
        { value: 'Truk', text: 'Truk' },
      ],
      optionsKategori: [
        { value: 1, text: 'Produk,' },
        { value: 2, text: 'Layanan' },
        { value: 3, text: 'Proses Produksi,' },
        { value: 4, text: 'Pengiriman,' },
        { value: 5, text: 'Paska Pengiriman,' },
        { value: 6, text: 'Harga' },
      ],
    }
  },
  methods: {
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'VehicleTraffic',
        },
      })
      this.file = data.data
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addRequest()
        }
      })
    },

    async addRequest() {
      await axios
        .post('siap/vehicle-traffic/create', {
          police_number: this.police_number,
          vehicle_type: this.vehicle_type,
          brand: this.brand,
          brand_type: '',
          type: this.type.value,
          color: this.color,
          date: dayjs(this.date).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          driver: this.Pengunjung,
          cat: this.kategori.text,
          note: this.catatan,
          desc: this.deskripsi,
          file: this.file,
          tags: this.tags,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          // window.location.href = `detail-disposisi/${response.data.data.id}`
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.errors.map(e => e.message),
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    addPengunjung() {
      this.Pengunjung = [
        ...this.Pengunjung,
        {
          name: '',
          visitor_number: '',
          phone: '',
          gender: '',
          email: '',
        },
      ]
    },

    deletePengunjung(index) {
      const current = this.Pengunjung.slice()
      current.splice(index, 1)
      this.Pengunjung = current
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.titlerow {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-left: 15px;
}

.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}

.bt-add {
  background: #fdf6eb;
}

.warning2 {
  font-size: 11px;
  color: orange;
  position: absolute;
  top: -1px;
  right: 15px;
  cursor: pointer;
}
</style>
